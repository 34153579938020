import React, { CSSProperties } from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import { button, icon, normal } from './button.module.scss';

interface IButtonElementProps extends React.ComponentProps<'button'> {
    as?: 'button';
    href?: never;
    to?: never;
}

interface IAnchorElementProps extends React.ComponentProps<'a'> {
    as: 'externalLink';
    href: string;
    to?: never;
}

interface ILinkComponentProps extends React.ComponentProps<typeof Link> {
    as: 'link';
    href?: never;
    to: string;
}

export type TButtonProps = {
    className?: string;
    kind?: 'normal' | 'icon';
    justify?: CSSProperties['justifyContent'];
} & (IButtonElementProps | IAnchorElementProps | ILinkComponentProps);

const Button: React.FC<TButtonProps> = ({
    className = '',
    kind = 'normal',
    as = 'button',
    justify = 'center',
    children,
    ...rest
}) => {
    const Tag = getTag(as);
    const kindClass = getKindClass(kind);

    return (
        <Tag
            className={`${button} ${className} ${kindClass}`}
            style={{ justifyContent: justify }}
            {...rest}
        >
            {children}
        </Tag>
    );
};

function getKindClass(kind: TButtonProps['kind']) {
    if (kind === 'icon') return icon;
    return normal;
}

function getTag(as: TButtonProps['as']): React.ElementType {
    if (as === 'externalLink') return 'a';
    if (as === 'link') return Link;
    return 'button';
}

export default Button;
