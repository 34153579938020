import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { heading, big, small, dark, light } from './heading.module.scss';

export type THeadingProps = {
    className?: string;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
    translatableTextKey: string;
    size?: 'big' | 'small';
    color?: 'dark' | 'light';
};

const Heading: React.FC<THeadingProps> = ({
    className = '',
    as = 'h1',
    translatableTextKey,
    size = 'big',
    color = 'light',
}) => {
    const { t } = useI18next();
    const Tag = as;

    return (
        <Tag
            className={`${heading} ${color === 'dark' ? dark : light} ${
                size === 'big' ? big : small
            } ${className}`}
            dangerouslySetInnerHTML={{
                __html: t(translatableTextKey, {
                    interpolation: { escapeValue: false },
                }),
            }}
        />
    );
};

export default Heading;
