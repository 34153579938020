import { useEffect, RefObject } from 'react';

export default function useOutsideElement(
    ref: RefObject<HTMLElement>,
    clickOutsideHandler: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                clickOutsideHandler();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clickOutsideHandler, ref]);
}
