import React from 'react';
import useIntersectionObserver from '../../hooks/use-intersection-observer';

import {
    wrapper,
    section,
    headingContainer,
    heading,
    form,
    semicircle,
    visibleSemicircle,
} from './contact.module.scss';

import Heading from '../atoms/heading';
import ContactForm from '../molecues/contact-form';
import SectionWrapper from '../molecues/section-wrapper';

interface IContactProps {
    className?: string;
}

const Contact: React.FC<IContactProps> = ({ className = '' }) => {
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);

    return (
        <SectionWrapper
            bgColor="dark"
            orientation="left"
            color="pink"
            isSemiCircle={true}
            semiCircleClassName={isOnScreen ? visibleSemicircle : semicircle}
            isOverflowHidden={false}
            className={`${className} ${wrapper}`}
        >
            <section className={section} ref={sectionRef}>
                <div className={headingContainer}>
                    <Heading
                        className={heading}
                        as="h2"
                        translatableTextKey="contact.heading"
                        color="light"
                    />
                </div>

                <ContactForm className={form} isHeading={false} />
            </section>
        </SectionWrapper>
    );
};

export default Contact;
