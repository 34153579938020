// extracted by mini-css-extract-plugin
export var arrowLight = "section-wrapper-module--arrow-light--GyiDS";
export var container = "section-wrapper-module--container--hhQPb";
export var dark = "section-wrapper-module--dark--kFSP4";
export var hidden = "section-wrapper-module--hidden--IqLmh";
export var layoutWithMarginLeft = "section-wrapper-module--layout-with-margin-left--GJ541";
export var layoutWithMarginRight = "section-wrapper-module--layout-with-margin-right--in3Gq";
export var layoutWithMargins = "section-wrapper-module--layout-with-margins--6Owe4";
export var layoutWithoutMargins = "section-wrapper-module--layout-without-margins--3Gfce";
export var light = "section-wrapper-module--light--AZYC5";
export var minimumFullScreenHeight = "section-wrapper-module--minimum-full-screen-height--UfHJ9";
export var paddingBottom = "section-wrapper-module--padding-bottom--0CcyN";
export var paddingTop = "section-wrapper-module--padding-top--TeBgl";
export var sectionWrapper = "section-wrapper-module--section-wrapper--Dgd4Y";
export var semicircle = "section-wrapper-module--semicircle--eqcf+";
export var visible = "section-wrapper-module--visible--oG3mK";