import React, { useEffect, useRef, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    sideInfo,
    description,
    fakeVideoBoxToAnimate,
    heading,
    subheading,
    button,
} from './side-info.module.scss';

import ButtonArrowIcon from '../../assets/images/svg/button-arrow.svg';
import Button from '../atoms/button';

interface ISideInfoProps {
    className?: string;
}

const SideInfo: React.FC<ISideInfoProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const fakeVideoBoxRef = useRef<HTMLDivElement>(null);
    const headingRef = useRef<HTMLHeadingElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (!fakeVideoBoxRef.current || !headingRef.current) {
                return;
            }

            const fakeVideoBoxDistanceToTop =
                window.scrollY + fakeVideoBoxRef.current.getBoundingClientRect().bottom;
            const headingDistanceToTop = window.innerHeight * 2 + headingRef.current.offsetTop;

            if (fakeVideoBoxDistanceToTop > headingDistanceToTop) {
                setHeight(fakeVideoBoxDistanceToTop - headingDistanceToTop);
            }
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`${sideInfo} ${className}`}>
            <h3 ref={headingRef} className={heading}>
                {t('lp1.wall.title')}
                <span style={{ height: `${height}px` }} data-text={t('lp1.wall.title')} />
            </h3>
            <p className={subheading}>{t('lp1.wall.small.title')}</p>
            <p className={description}>
                {t('lp1.wall.text.bottom.1')}
                <br />
                {t('lp1.wall.text.bottom.2')}
            </p>
            <Button as="externalLink" href="https://alterpage.pl/portfolio" className={button}>
                {t('lp1.wall.button')} <ButtonArrowIcon />
            </Button>
            <div ref={fakeVideoBoxRef} className={fakeVideoBoxToAnimate} />
        </div>
    );
};

export default SideInfo;
