import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { wrapper, section, heading, list, semicircle, visible, buttonFaq } from './faq.module.scss';

import SectionWrapper from '../molecues/section-wrapper';
import Heading from '../atoms/heading';
import AccordionList from '../molecues/accordion-list';

interface IFAQProps {
    className?: string;
}

const getItemsDataFirst = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(5).fill(0).map((item, index) => ({
        title: t(`lp1.faq.question.${index + 1}`),
        content: t(`lp1.faq.answer.${index + 1}`),
    }));
};

const getItemsDataSecond = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(23).fill(0).map((item, index) => ({
        title: t(`lp1.faq.question.${index + 6}`),
        content: t(`lp1.faq.answer.${index + 6}`),
    }));
};

const FAQ: React.FC<IFAQProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const itemsDataFirst = getItemsDataFirst(t);
    const itemsDataSecond = getItemsDataSecond(t);
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);
    const [isActive, setIsActive] = useState(false);

    return (
        <SectionWrapper
            bgColor="light"
            isSemiCircle={true}
            semiCircleClassName={semicircle}
            orientation="right"
            className={`${className} ${wrapper} ${isOnScreen ? visible : ''}`}
        >
            <section className={`${section}`} ref={sectionRef}>
                <Heading
                    className={heading}
                    as="h1"
                    translatableTextKey="faq.heading"
                    color="dark"
                />
                <AccordionList className={list} items={itemsDataFirst} />

                <motion.div
                    style={{ overflow: isActive ? 'visible' : 'hidden' }}
                    initial={{ height: 0 }}
                    animate={{ height: isActive ? 'auto' : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <AccordionList items={itemsDataSecond} className={list} />
                </motion.div>

                {!isActive && (
                    <button onClick={() => setIsActive(!isActive)} className={buttonFaq}>
                        <span>{t('faq.button')}</span>
                        <span>{t('faq.button')}</span>
                    </button>
                )}
            </section>
        </SectionWrapper>
    );
};

export default FAQ;
