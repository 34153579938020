// extracted by mini-css-extract-plugin
export var button = "contact-form-module--button--BJEDR";
export var buttons = "contact-form-module--buttons--cDje9";
export var error = "contact-form-module--error--3Pntp";
export var errorGlobal = "contact-form-module--error-global--uXogz";
export var errorStatus = "contact-form-module--error-status--5Jn-f";
export var form = "contact-form-module--form--rbg5M";
export var formLoading = "contact-form-module--form-loading--jzzlj";
export var formSuccess = "contact-form-module--form-success--4p8qK";
export var innerForm = "contact-form-module--inner-form--HDSvr";
export var input = "contact-form-module--input--SqmID";
export var label = "contact-form-module--label--E7k8r";
export var successText = "contact-form-module--success-text--kKIgH";
export var text = "contact-form-module--text--NIB70";
export var textarea = "contact-form-module--textarea--C9L4I";
export var title = "contact-form-module--title--NHWzR";