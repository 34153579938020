import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';

import {
    slider,
    sliderWrapper,
    swiper,
    slide,
    heading,
    btnPrev,
    btnNext,
    dots,
    dot,
    activeDot,
} from './slider.module.scss';
import 'swiper/scss';

import PrevArrowIcon from '../../assets/images/svg/slider-prev-arrow.svg';
import NextArrowIcon from '../../assets/images/svg/slider-next-arrow.svg';
import Button from '../atoms/button';

interface ISliderProps {
    className?: string;
    activeIndex: number;
    onSlideChange: React.Dispatch<React.SetStateAction<number>>;
}

const settings: SwiperProps = {
    loop: true,
    allowTouchMove: false,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
};

SwiperCore.use([Navigation, EffectFade]);

const Slider: React.FC<ISliderProps> = ({ className = '', onSlideChange }) => {
    const { t } = useI18next();
    const [headingText, setHeadingText] = useState<string>(t('portfolio.slider.title.1'));
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
    const data = useStaticQuery(graphql`
        {
            allFile(
                filter: { relativeDirectory: { eq: "portfolio/works" } }
                sort: { fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    `);

    const dotsMarkup = (swiperInstance: SwiperCore) => {
        return (
            <div className={dots}>
                {[...Array(data.allFile.nodes.length)].map((x, idx) => {
                    return (
                        <button
                            className={`${dot} ${
                                swiperInstance.realIndex === idx ? activeDot : ''
                            }`}
                            onClick={() => {
                                onSlideChange(idx);

                                setTimeout(() => {
                                    swiperInstance.slideToLoop(idx);
                                    setHeadingText(t(`portfolio.slider.title.${idx + 1}`));
                                }, 500);
                            }}
                        />
                    );
                })}
            </div>
        );
    };

    const changeSlideHandle = (direction: boolean) => {
        if (!swiperInstance) return;

        const realIndex = swiperInstance.realIndex;
        const itemsCount = data.allFile.nodes.length;
        let index = realIndex === 0 ? itemsCount - 1 : realIndex - 1;

        if (direction) {
            index = realIndex === itemsCount - 1 ? 0 : realIndex + 1;
        }

        onSlideChange(index);

        setTimeout(() => {
            direction ? swiperInstance.slideNext() : swiperInstance.slidePrev();
            setHeadingText(t(`portfolio.slider.title.${index + (direction ? 1 : -1)}`));
        }, 500);
    };

    return (
        <div className={`${slider} ${className}`}>
            <p className={heading}>{headingText}</p>
            <div className={sliderWrapper}>
                <Swiper
                    {...settings}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    className={swiper}
                >
                    {[...Array(3)].map((x, i) => {
                        const image = getImage(data.allFile.nodes[i]);
                        return (
                            <SwiperSlide className={slide}>
                                {image && <GatsbyImage alt={''} image={image} />}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <Button className={btnPrev} onClick={() => changeSlideHandle(false)}>
                    <PrevArrowIcon />
                </Button>
                <Button className={btnNext} onClick={() => changeSlideHandle(true)}>
                    <NextArrowIcon />
                </Button>
            </div>
            {swiperInstance && dotsMarkup(swiperInstance)}
        </div>
    );
};

export default Slider;
