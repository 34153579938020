import React, { useEffect, useState } from 'react';

import {
    container,
    circleBox,
    ratio,
    circle,
    top,
    bottom,
    left,
    right,
    blue,
    pink,
    purple,
    yellow,
    full,
    half,
} from './semicircle.module.scss';
import { getRandomInteger } from '../../utils/get-random-integer';

export type TSemicircleOrientation = 'top' | 'bottom' | 'left' | 'right';
export type TAccentColor = 'yellow' | 'blue' | 'purple' | 'pink';
export type TSemicircleContainerType = 'full' | 'half';

export interface ISemicircleProps {
    className?: string;
    orientation?: TSemicircleOrientation;
    color?: TAccentColor | 'random';
    containerType?: TSemicircleContainerType;
}

const Semicircle: React.FC<ISemicircleProps> = ({
    className = '',
    orientation = 'top',
    color = 'yellow',
    containerType = 'half',
}) => {
    const [colorClass, setColorClass] = useState(getColorClass(color));
    const orientationClass = orientationClasses[orientation];
    const typeClass = typeClasses[containerType];

    useEffect(() => {
        if (color !== 'random') return;
        const interval = setInterval(() => {
            setColorClass(getColorClass(color));
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [color]);

    return (
        <div className={`${container} ${className} ${orientationClass} ${colorClass} ${typeClass}`}>
            <div className={ratio}>
                <div className={circleBox}>
                    <div className={circle} />
                </div>
            </div>
        </div>
    );
};

function getColorClass(color: ISemicircleProps['color'] = 'yellow') {
    let newColor;
    if (color === 'random') {
        const colors: TAccentColor[] = ['blue', 'yellow', 'purple', 'pink'];
        newColor = colors[getRandomInteger(0, colors.length - 1)];
    } else {
        newColor = color;
    }
    return colorClasses[newColor];
}

const orientationClasses: Record<TSemicircleOrientation, string> = {
    top: top,
    bottom: bottom,
    left: left,
    right: right,
};

const colorClasses: Record<TAccentColor, string> = {
    blue: blue,
    pink: pink,
    purple: purple,
    yellow: yellow,
};

const typeClasses: Record<TSemicircleContainerType, string> = {
    full: full,
    half: half,
};

export default Semicircle;
