import React, { useEffect, useState, useRef } from 'react';
import useOutsideElement from '../../hooks/use-outside-element';

import {
    sectionWrapper,
    section,
    semicircle,
    form,
    modalOpened,
    modalClosed,
    modalBackground,
    close,
    blurredButton,
} from './modal-form.module.scss';

import SectionWrapper from '../molecues/section-wrapper';
import Semicircles from '../atoms/semicircles';
import ContactForm from '../molecues/contact-form';
import ModalButton from '../atoms/modal-button';
import CloseButton from '../atoms/close-button';

interface IModalFormProps {
    className?: string;
    isFirstInitialization: boolean;
    setIsFirstInitialization?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsBlurred?: React.Dispatch<React.SetStateAction<boolean>>;
    modalButtonclassName?: string;
}

const ModalForm: React.FC<IModalFormProps> = ({
    className = '',
    isFirstInitialization,
    setIsFirstInitialization,
    setIsBlurred,
    modalButtonclassName,
}) => {
    const [isModalOpened, setIsModalOpened] = useState(true);
    const sectionRef = useRef(null);

    const modalButtonHandler = () => {
        setIsModalOpened(false);

        if (isFirstInitialization && setIsFirstInitialization) {
            setIsFirstInitialization(false);
        }
    };

    useOutsideElement(sectionRef, () => modalButtonHandler());

    useEffect(() => {
        if (!setIsBlurred) return;

        setIsBlurred(isModalOpened);
    }, [isModalOpened, setIsBlurred]);

    return (
        <>
            <SectionWrapper
                bgColor="dark"
                className={`${className} ${sectionWrapper} ${
                    isModalOpened ? modalOpened : modalClosed
                } ${isFirstInitialization ? modalBackground : ''}`}
            >
                <Semicircles className={semicircle} />
                <section className={section} ref={sectionRef}>
                    <CloseButton className={close} onClick={modalButtonHandler} />
                    <ContactForm
                        className={form}
                        isModalButton={true}
                        modalButtonOnClick={modalButtonHandler}
                    />
                </section>
            </SectionWrapper>

            {!isFirstInitialization && (
                <ModalButton
                    className={modalButtonclassName}
                    onClick={() => setIsModalOpened(!isModalOpened)}
                />
            )}
        </>
    );
};

export default ModalForm;
