import React from 'react';

import { list } from './accordion-list.module.scss';

import AccordionItem from '../atoms/accordion-item';

import { TAccordionItemProps } from '../atoms/accordion-item';

export type TAccordionListProps = {
    className?: string;
    items: TAccordionItemProps[];
};

const AccordionList: React.FC<TAccordionListProps> = ({ className = '', items }) => {
    return (
        <ul className={`${list} ${className}`}>
            {items.map((item, idx) => (
                <AccordionItem title={item.title} content={item.content} key={idx} />
            ))}
        </ul>
    );
};

export default AccordionList;
