import React, { useRef, useEffect } from 'react';
import { CookiesBar, CookiesHelpers } from '@alterpage/cookies-widget';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { layout, cookiesBar, cookiesStyles, cookiesLogo } from './main-layout.module.scss';
import { cookies, cookiesConfig as defaultCookiesConfig } from '../config/cookies';
import Logo from '../assets/images/svg/logo.svg';
import { ISEOProps } from '../components/seo';
import useCookiesTranslations from "../hooks/use-cookies-translations";

import SEO from '../components/seo';
import Header from '../components/organisms/header';
import Intro from '../components/organisms/intro';

interface IMainLayoutProps {
    className?: string;
    children?: React.ReactNode;
    SEOProps?: ISEOProps;
    headerClassName?: string;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ className = '', children, headerClassName }) => {
    const { language } = useI18next();
    const location = useLocation();
    const cookiesConfig = useCookiesTranslations(defaultCookiesConfig);
    const cookiesRef = useRef<CookiesHelpers>();

    useEffect(() => {
        const now = new Date().getTime();
        const expirationTime = now + 1000*36000;
        const expirationDate = new Date(expirationTime);
        document.cookie=`AP_ADS_AP=true;expires=${expirationDate};path=/`;
        initializeAndTrack(location);
    }, []);

    return (
        <>
            <SEO />
            <div className={layout}>
                <Header className={headerClassName} />
                <main className={className}>{children}</main>
                <CookiesBar
                    className={cookiesBar}
                    ref={cookiesRef}
                    Logo={<Logo className={cookiesLogo} />}
                    cookies={cookies}
                    language={language}
                    config={cookiesConfig}
                    commonClassName={cookiesStyles}
                />
            </div>
            <Intro />
        </>
    );
};

export default MainLayout;
