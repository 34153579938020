import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { graphql, useStaticQuery } from 'gatsby';
import SwiperCore, { EffectFade, Navigation } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    wrapper,
    section,
    headingContainer,
    heading,
    company,
    blockquote,
    icon,
    visible,
    slider,
    slide,
    activeSlide,
    yellow,
    blue,
    purple,
    logo,
    image,
    dots,
    dot,
    activeDot,
    subheading,
    description,
    button,
    author,
    quote,
    swiperContainer,
    btnNext,
    btnPrev,
} from './portfolio-mobile.module.scss';

import SectionWrapper from '../molecues/section-wrapper';
import Button from '../atoms/button';
import ButtonArrowIcon from '../../assets/images/svg/button-arrow.svg';
import QuoteIcon from '../../assets/images/svg/quote-white.svg';
import Semicircles from '../atoms/semicircles';
import PrevArrowIcon from '../../assets/images/svg/slider-prev-arrow.svg';
import NextArrowIcon from '../../assets/images/svg/slider-next-arrow.svg';

interface IPortfolioMobileProps {
    className?: string;
}

const settings: SwiperProps = {
    className: slider,
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
};

SwiperCore.use([Navigation, EffectFade]);

const PortfolioMobile: React.FC<IPortfolioMobileProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const itemsData = getData(t);
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
    const [color, setColor] = useState(yellow);

    const data = useStaticQuery(graphql`
        {
            allFile(
                filter: { relativeDirectory: { in: ["portfolio/logos", "portfolio/works-mobile"] } }
                sort: { fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    `);

    const nodes = data.allFile.nodes;
    const half = Math.ceil(nodes.length / 2);
    const logos = nodes.slice(0, half);
    const works = nodes.slice(-half);

    const changeSlideHandle = (direction: boolean) => {
        if (!swiperInstance) return;

        direction ? swiperInstance.slideNext() : swiperInstance.slidePrev();
    };

    return (
        <SectionWrapper
            bgColor="light"
            className={`${className} ${wrapper}`}
            isPaddingBottom={false}
            isPaddingTop={false}
        >
            <section className={`${section} ${isOnScreen ? visible : ''}`} ref={sectionRef}>
                <div>
                    <div className={headingContainer}>
                        <Semicircles />
                        <h3 className={heading}>Wall of fame</h3>
                    </div>
                    <div className={swiperContainer}>
                        <Swiper
                            {...settings}
                            onSwiper={(swiper) => !swiperInstance && setSwiperInstance(swiper)}
                            onSlideChange={(swiper) => setColor(getColor(swiper.realIndex))}
                            className={`${color} ${slider}`}
                        >
                            {itemsData.map((item, idx) => {
                                const imageLogo = getImage(logos[idx]);
                                const imageWork = getImage(works[idx]);

                                return (
                                    <SwiperSlide
                                        key={idx}
                                        className={`${slide} ${
                                            swiperInstance && swiperInstance.realIndex === idx
                                                ? activeSlide
                                                : ''
                                        }`}
                                    >
                                        {imageLogo && (
                                            <GatsbyImage
                                                className={logo}
                                                image={imageLogo}
                                                alt=""
                                            />
                                        )}
                                        <p className={company}>{item.company}</p>
                                        {imageWork && (
                                            <GatsbyImage
                                                className={image}
                                                image={imageWork}
                                                alt=""
                                            />
                                        )}
                                        {swiperInstance && dotsMarkup(swiperInstance)}
                                        <blockquote className={blockquote}>
                                            <QuoteIcon className={icon} />
                                            <p className={quote}>{item.quote}</p>
                                            <footer className={author}>
                                                - {item.author}{' '}
                                                {item.position && <span>{item.position}</span>}
                                            </footer>
                                        </blockquote>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <Button className={btnPrev} onClick={() => changeSlideHandle(false)}>
                            <PrevArrowIcon />
                        </Button>
                        <Button className={btnNext} onClick={() => changeSlideHandle(true)}>
                            <NextArrowIcon />
                        </Button>
                    </div>
                    <p className={subheading}>{t('lp1.wall.small.title')}</p>
                    <p className={description}>
                        {t('lp1.wall.text.bottom.1')}
                        <br />
                        {t('lp1.wall.text.bottom.2')}
                    </p>
                    <Button
                        as="externalLink"
                        href="https://alterpage.pl/portfolio"
                        className={button}
                    >
                        {t('lp1.wall.button')} <ButtonArrowIcon />
                    </Button>
                </div>
            </section>
        </SectionWrapper>
    );
};

const getData = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(3).fill(0).map((item, index) => ({
        company: t(`portfolio.company.${index + 1}`),
        quote: t(`portfolio.quote.${index + 1}`),
        author: t(`portfolio.author.${index + 1}`),
        position: t(`portfolio.position.${index + 1}`),
    }));
};

const dotsMarkup = (swiperInstance: SwiperCore) => {
    return (
        <div className={dots}>
            {new Array(3).fill(0).map((item, idx) => (
                <button
                    className={`${dot} ${swiperInstance.realIndex === idx ? activeDot : ''}`}
                    onClick={() => swiperInstance.slideToLoop(idx)}
                />
            ))}
        </div>
    );
};

const getColor = (activeIndex: number) => {
    switch (activeIndex) {
        case 0:
            return yellow;
        case 1:
            return blue;
        case 2:
            return purple;
    }
};

export default PortfolioMobile;
