import React from 'react';
import useIntersectionObserver from '../../hooks/use-intersection-observer';

import { videoContainer, video, visible, container } from './video-hero.module.scss';

import VideoSrc from '../../assets/videos/hero_video.mp4';
import Thumbnail from '../../assets/images/thumbnail-video.png';

interface IVideoHeroProps {
    className?: string;
}

const VideoHero: React.FC<IVideoHeroProps> = ({ className = '' }) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const isOnScreen = useIntersectionObserver(videoRef);

    return (
        <div className={`${className} ${isOnScreen ? visible : ''} ${container}`}>
            <div className={videoContainer}>
                <video controls className={video} poster={Thumbnail} ref={videoRef}>
                    <source src={VideoSrc} type="video/mp4" />
                </video>
            </div>
        </div>
    );
};

export default VideoHero;
