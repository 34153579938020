// extracted by mini-css-extract-plugin
export var activeDot = "portfolio-mobile-module--active-dot--iSmZC";
export var activeSlide = "portfolio-mobile-module--active-slide--lIhIB";
export var author = "portfolio-mobile-module--author--T6zGk";
export var blockquote = "portfolio-mobile-module--blockquote--jWpNV";
export var blue = "portfolio-mobile-module--blue--bEAuN";
export var btnNext = "portfolio-mobile-module--btn-next--0Hr5V";
export var btnPrev = "portfolio-mobile-module--btn-prev--gRaMt";
export var button = "portfolio-mobile-module--button--eeH+m";
export var company = "portfolio-mobile-module--company--s7kiL";
export var description = "portfolio-mobile-module--description--zNhN0";
export var dot = "portfolio-mobile-module--dot--W-mbV";
export var dots = "portfolio-mobile-module--dots--dPdMf";
export var dotsContainer = "portfolio-mobile-module--dots-container--a6P7C";
export var heading = "portfolio-mobile-module--heading--JuXbm";
export var headingContainer = "portfolio-mobile-module--heading-container--0LzrD";
export var icon = "portfolio-mobile-module--icon--FCUyE";
export var image = "portfolio-mobile-module--image--9LUiw";
export var logo = "portfolio-mobile-module--logo--M5gNN";
export var purple = "portfolio-mobile-module--purple--hP79R";
export var quote = "portfolio-mobile-module--quote--lnBLN";
export var section = "portfolio-mobile-module--section--Tm2Km";
export var slide = "portfolio-mobile-module--slide--r1Skl";
export var slider = "portfolio-mobile-module--slider--B1nbC";
export var subheading = "portfolio-mobile-module--subheading--m08Zg";
export var swiperContainer = "portfolio-mobile-module--swiper-container--qZFH1";
export var wrapper = "portfolio-mobile-module--wrapper--U3pdQ";
export var yellow = "portfolio-mobile-module--yellow--vFn9B";