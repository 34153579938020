import React, { useEffect, useRef, useState } from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import {
    container,
    open,
    innerContainer,
    logoLink,
    buttons,
    hamburgerBox,
    closeButton,
    scrolled,
    layer,
} from './header.module.scss';
import { gridWide } from '../../styles/grid.module.scss';
import Logo from '../../assets/images/svg/logo.svg';

import Hamburger from '../atoms/hamburger';
import Navigation from '../molecues/navigation';
import CloseButton from '../atoms/close-button';

interface IHeaderProps {
    className?: string;
}

const Header: React.FC<IHeaderProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLayerActive, setIsLayerActive] = useState(false);

    const handleOpen = () => {
        if (!isLayerActive) {
            setIsLayerActive(true);
        }
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className={`${container} ${gridWide} ${className} ${isScrolled ? scrolled : ''} ${
                isOpen ? open : ''
            }`}
        >
            <div className={innerContainer}>
                <Link to="/" aria-label={t('header.logo.link')} className={logoLink}>
                    <Logo />
                </Link>
                <div className={buttons}>
                    <div className={hamburgerBox}>
                        <motion.div
                            variants={hamburgerVariants}
                            initial="initial"
                            animate={isOpen ? 'opened' : 'closed'}
                        >
                            <Hamburger onClick={handleOpen} />
                        </motion.div>
                        <motion.div
                            className={closeButton}
                            variants={closeButtonVariants}
                            initial="initial"
                            animate={isOpen ? 'opened' : 'closed'}
                        >
                            <CloseButton onClick={handleOpen} />
                        </motion.div>
                    </div>
                </div>
            </div>
            <Navigation isOpen={isOpen} />
            {isLayerActive && (
                <motion.div
                    className={layer}
                    variants={layerVariants}
                    animate={isOpen ? 'opened' : 'closed'}
                />
            )}
        </div>
    );
};

const hamburgerVariants: Variants = {
    initial: {
        scale: 1,
    },
    opened: {
        scale: 0,
        pointerEvents: 'none',
        transition: {
            duration: 0.25,
            ease: 'linear',
        },
    },
    closed: {
        scale: 1,
        transition: {
            duration: 0.25,
            ease: 'linear',
            delay: 0.5,
        },
    },
};

const closeButtonVariants: Variants = {
    initial: {
        scale: 0,
    },
    opened: {
        scale: 1,
        transition: {
            delay: 0.25,
            duration: 0.25,
            ease: 'linear',
        },
    },
    closed: {
        scale: 0,
        pointerEvents: 'none',
        transition: {
            duration: 0.25,
            ease: 'linear',
        },
    },
};

const layerVariants: Variants = {
    initial: {
        clipPath: 'polygon(0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%)',
    },
    closed: {
        clipPath: [
            'polygon(0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 0%, 0% 0%, 100% 100%, 100% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)',
            'polygon(0% 0%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)',
        ],
        transition: {
            duration: 0.5,
            ease: 'linear',
        },
    },
    opened: {
        clipPath: [
            'polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)',
            'polygon(0% 0%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'polygon(0% 100%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 0%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 0%, 0% 0%, 100% 100%, 100% 100%, 0% 100%)',
            'polygon(0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%)',
        ],
        transition: {
            delay: 0.25,
            duration: 0.5,
            ease: 'linear',
        },
    },
};

export default Header;
