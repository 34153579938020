import React from 'react';
import Lottie from 'react-lottie';

import { container, complete, animation } from './intro.module.scss';
import introAnimation from '../../assets/lottie/intro.json';
import { useIntroContext } from '../../contexts/intro-context';

interface IIntroProps {
    className?: string;
}

const Intro: React.FC<IIntroProps> = ({ className = '' }) => {
    const { isComplete, shouldPlay, handleComplete } = useIntroContext();

    if (!shouldPlay) return null;

    return (
        <div className={`${container} ${className} ${isComplete ? complete : ''}`}>
            <Lottie
                options={{
                    animationData: introAnimation,
                    loop: false,
                    autoplay: true,
                    rendererSettings: {
                        className: animation,
                    },
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
                isClickToPauseDisabled={true}
                eventListeners={[
                    {
                        eventName: 'complete',
                        callback: handleComplete,
                    },
                ]}
            />
        </div>
    );
};

export default Intro;
