import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import { heading, slider, subheading, description, visible } from './welcome-hero.module.scss';
import 'swiper/scss';

import Heading from '../atoms/heading';
import Semicircles from '../atoms/semicircles';

interface IWelcomeHeroProps {
    className?: string;
}

const settings: SwiperProps = {
    loop: true,
    allowTouchMove: false,
    autoplay: { delay: 2500, disableOnInteraction: false },
};

SwiperCore.use([Autoplay]);

const WelcomeHero: React.FC<IWelcomeHeroProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);

    return (
        <section className={`${isOnScreen ? visible : ''} ${className}`} ref={sectionRef}>
            <Semicircles />
            <Heading className={heading} as="h1" translatableTextKey="welcome.top.heading" />
            <Swiper {...settings} className={slider}>
                {[...Array(5)].map((x, idx) => {
                    return (
                        <SwiperSlide>
                            <p className={subheading}>{t(`welcome.subtitle${idx + 1}`)}</p>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <p className={description}>{t('welcome.top.description')}</p>
        </section>
    );
};

export default WelcomeHero;
