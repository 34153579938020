import React, { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { useStaticQuery, graphql } from 'gatsby';

import { heading, visibleHeading, description, visibleDescription } from './partners.module.scss';
import 'swiper/scss';

import Heading from '../atoms/heading';
import SectionWrapper from '../molecues/section-wrapper';
import Semicircles from '../atoms/semicircles';

interface IPartnersProps {
    className?: string;
}

const settings = {
    loop: true,
    spaceBetween: 20,
    slidesPerView: 2,
    breakpoints: {
        600: {
            slidesPerView: 3,
            spaceBetween: 35,
        },
        840: {
            slidesPerView: 4,
            spaceBetween: 50,
        },
        1200: {
            spaceBetween: 65,
            slidesPerView: 5,
        },
    },
    autoplay: { delay: 1000, disableOnInteraction: false },
};

SwiperCore.use([Autoplay]);

const Partners: React.FC<IPartnersProps> = ({ className = '' }) => {
    const headingRef = useRef<HTMLHeadingElement>(null);
    const descriptionRef = useRef<HTMLParagraphElement>(null);
    const isHeadingOnScreen = useIntersectionObserver(headingRef);
    const isDescriptionOnScreen = useIntersectionObserver(descriptionRef);

    const data = useStaticQuery(graphql`
        query MyQuery {
            allFile(filter: { relativeDirectory: { eq: "cooperation" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(width: 240)
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <SectionWrapper
                bgColor="dark"
                isPaddingBottom={false}
                isMinimumFullScreenHeight={false}
                className={className}
            >
                <Semicircles />
                <div ref={headingRef}>
                    <Heading
                        className={`${heading} ${isHeadingOnScreen ? visibleHeading : ''}`}
                        as="h2"
                        translatableTextKey="partners.heading"
                        size="small"
                    />
                </div>
            </SectionWrapper>

            <SectionWrapper
                bgColor="dark"
                isMarginRight={false}
                isPaddingTop={false}
                isPaddingBottom={false}
                isMinimumFullScreenHeight={false}
                className={className}
            >
                <Swiper {...settings}>
                    {[...Array(20)].map((x, i) => {
                        const image = getImage(data.allFile.edges[i].node);
                        return (
                            <SwiperSlide>
                                {image && <GatsbyImage alt={''} image={image} />}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </SectionWrapper>

            <SectionWrapper
                bgColor="dark"
                isPaddingTop={false}
                isMinimumFullScreenHeight={false}
                className={className}
            >
                <Semicircles />
                <div ref={descriptionRef}>
                    <Heading
                        className={`${description} ${
                            isDescriptionOnScreen ? visibleDescription : ''
                        }`}
                        as="p"
                        translatableTextKey="partners.description"
                        size="small"
                    />
                </div>
            </SectionWrapper>
        </>
    );
};

export default Partners;
