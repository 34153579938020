import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    section,
    heading,
    info,
    footer,
    subheading,
    description,
    bottomText,
} from './about.module.scss';

import Heading from '../atoms/heading';
import Semicircles from '../atoms/semicircles';
import ContactDetails from '../molecues/contact-details';
import SectionWrapper from '../molecues/section-wrapper';

interface IAboutProps {
    className?: string;
}

const About: React.FC<IAboutProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <SectionWrapper bgColor="dark" className={className}>
            <Semicircles />
            <section className={section}>
                <Heading
                    className={heading}
                    as="h2"
                    translatableTextKey="about.heading"
                    color="light"
                />
                <ContactDetails className={info} />
                <footer className={footer}>
                    <p className={subheading}>{t('about.subheading')}</p>
                    <p className={description}>{t('about.description')}</p>
                </footer>

                <p
                    className={bottomText}
                    dangerouslySetInnerHTML={{
                        __html: t('about.bottom', {
                            interpolation: { escapeValue: false },
                        }),
                    }}
                />
            </section>
        </SectionWrapper>
    );
};

export default About;
