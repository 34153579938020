import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import statusMap from '../../config/status-map';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { postForm } from '../../api/form';

import {
    title,
    form,
    innerForm,
    text,
    container,
    label,
    textarea,
    input,
    buttons,
    button,
    error,
    formLoading,
    formSuccess,
    successText,
    errorGlobal,
} from './contact-form.module.scss';

interface IContactFormProps {
    className?: string;
    isHeading?: boolean;
    isModalButton?: boolean;
    modalButtonOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

interface ILandingForm {
    name: string;
    email: string;
    content: string;
    form: string;
}

const initialValues = {
    name: '',
    email: '',
    content: '',
    form: 'v2',
};

const getFieldsValidation = (t: ReturnType<typeof useI18next>['t']) => {
    return {
        name: Yup.string()
            .required(t('lp1.form.error.required.field'))
            .max(128, t('lp1.form.name.max.range')),
        email: Yup.string()
            .required(t('lp1.form.error.required.field'))
            .email(t('lp1.form.error.email')),
        content: Yup.string()
            .required(t('lp1.form.error.required.field'))
            .max(1024, t('lp1.form.content.max.range')),
    };
};

const handleSubmit = async (values: ILandingForm, helpers: FormikHelpers<any>): Promise<any> => {
    helpers.setStatus(statusMap.loading);
    postForm(values)
        .then(() => {
            helpers.setStatus(statusMap.success);
        })
        .catch(() => {
            helpers.setStatus(statusMap.error);
        });
};

const ContactForm: React.FC<IContactFormProps> = ({
    className = '',
    isHeading = true,
    isModalButton,
    modalButtonOnClick,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            {isHeading && (
                <h4 className={title}>
                    {t('lp1.hero.form.title1')} <span>{t('lp1.hero.form.title2')}</span>
                </h4>
            )}

            <Formik
                initialValues={{ ...initialValues }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                    ...getFieldsValidation(t),
                })}
            >
                {(formik) => (
                    <Form className={form}>
                        {formik.status !== 'success' && (
                            <div
                                className={`${innerForm} ${
                                    formik.status === 'loading' ? formLoading : ''
                                } ${formik.status === 'success' ? formSuccess : ''}`}
                            >
                                <label htmlFor="name" className={label}>
                                    Imię
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder="Imię i Nazwisko/pseudonim"
                                    className={input}
                                />
                                <p className={error}>
                                    <ErrorMessage name="name" />
                                </p>
                                <label htmlFor="email" className={label}>
                                    Adres email
                                </label>
                                <Field
                                    id="email"
                                    name="email"
                                    placeholder="Adres email"
                                    type="email"
                                    className={input}
                                />
                                <p className={error}>
                                    <ErrorMessage name="email" className={error} />
                                </p>
                                <label htmlFor="content" className={label}>
                                    Co możemy dla Ciebie zrobić?
                                </label>
                                <Field
                                    as="textarea"
                                    id="content"
                                    name="content"
                                    placeholder="Opisz swój projekt"
                                    className={textarea}
                                />
                                <p className={error}>
                                    <ErrorMessage name="content" className={error} />
                                </p>
                                <p className={text}>{t('lp1.hero.form.text')}</p>
                                <p className={errorGlobal}>
                                    {formik.status === 'error' &&
                                        `Wystąpił błąd, spróbuj później...`}
                                </p>
                                <div className={buttons}>
                                    {isModalButton && (
                                        <button
                                            type="button"
                                            className={button}
                                            onClick={modalButtonOnClick}
                                        >
                                            <span>może później</span>
                                            <span>może później</span>
                                        </button>
                                    )}

                                    <button type="submit" className={button}>
                                        <span>wyślij</span>
                                        <span>wyślij</span>
                                    </button>
                                </div>
                            </div>
                        )}
                        {formik.status === 'success' && (
                            <p className={successText}>
                                Dziękujemy za wiadomość, wkrótce się z Tobą skontaktujemy.
                            </p>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
