// extracted by mini-css-extract-plugin
export var animated = "reviews-module--animated--VoBjl";
export var animatedSwiper = "reviews-module--animated-swiper--R-aWK";
export var author = "reviews-module--author--R7cQF";
export var blockquote = "reviews-module--blockquote--2HVc+";
export var blue = "reviews-module--blue--nV006";
export var company = "reviews-module--company--BglKV";
export var icon = "reviews-module--icon--2wYIn";
export var logo = "reviews-module--logo--aEQZ1";
export var mymove = "reviews-module--mymove--COeY8";
export var purple = "reviews-module--purple--tLhSN";
export var quote = "reviews-module--quote--aHZzL";
export var reviews = "reviews-module--reviews--jOWBv";
export var unanimatedSwiper = "reviews-module--unanimated-swiper--Cgow7";
export var wwww = "reviews-module--wwww--G1Y68";
export var yellow = "reviews-module--yellow--qUjzh";