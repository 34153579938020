import React, { useState } from 'react';
import useIntersectionObserver from '../../hooks/use-intersection-observer';

import { wrapper, section, visible } from './portfolio.module.scss';

import SectionWrapper from '../molecues/section-wrapper';
import Reviews from '../molecues/reviews';
import Slider from '../molecues/slider';
import SideInfo from '../molecues/side-info';

interface IPortfolioProps {
    className?: string;
}

const Portfolio: React.FC<IPortfolioProps> = ({ className = '' }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);

    return (
        <SectionWrapper
            bgColor="light"
            className={`${className} ${wrapper}`}
            isPaddingBottom={false}
            isPaddingTop={false}
        >
            <section
                className={`${section} ${isOnScreen ? visible : ''}`}
                id="wall-of-fame"
                ref={sectionRef}
            >
                <Reviews activeIndex={activeIndex} />
                <Slider activeIndex={activeIndex} onSlideChange={setActiveIndex} />
                <SideInfo />
            </section>
        </SectionWrapper>
    );
};

export default Portfolio;
