import React from 'react';

import { container, semicircle, arrowDown } from './button-scroll-down.module.scss';

import ArrowDown from '../../assets/images/svg/arrow-down.svg';
import Semicircle from '../atoms/semicircle';
import { IScrollTo } from '../../models/scroll-to.model';

interface IScrollDownProps {
    className?: string;
    scrollToSection?: IScrollTo;
}

const ButtonScrollDown: React.FC<IScrollDownProps> = ({ className = '', scrollToSection = '' }) => {
    return (
        <div className={`${className} ${container}`}>
            <a href={`#${scrollToSection}`} type="button">
                <Semicircle
                    containerType={'half'}
                    orientation={'top'}
                    className={semicircle}
                    color={'pink'}
                />
                <ArrowDown className={arrowDown} />
            </a>
        </div>
    );
};

export default ButtonScrollDown;
