import React from 'react';

import { semicircle } from './semicircles.module.scss';

import Semicircle from '../atoms/semicircle';

interface ISemicirclesProps {
    className?: string;
}

const Semicircles: React.FC<ISemicirclesProps> = ({ className = '' }) => {
    const semicirclesAmount = 20;

    return (
        <div className={className}>
            {[...Array(semicirclesAmount).keys()].map((val, idx) => (
                <Semicircle className={semicircle} color="random" key={idx} />
            ))}
        </div>
    );
};

export default Semicircles;
