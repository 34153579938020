import React, { useEffect, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';

import {
    reviews,
    logo,
    animated,
    animatedSwiper,
    unanimatedSwiper,
    company,
    blockquote,
    icon,
    quote,
    author,
    container,
    yellow,
    blue,
    purple,
} from './reviews.module.scss';
import 'swiper/css/effect-fade';

import QuoteIcon from '../../assets/images/svg/quote-white.svg';

interface IReviewsProps {
    className?: string;
    activeIndex: number;
}

const settings: SwiperProps = {
    loop: false,
    allowTouchMove: false,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
};

SwiperCore.use([EffectFade]);

const Reviews: React.FC<IReviewsProps> = ({ className = '', activeIndex }) => {
    const { t } = useI18next();
    const itemsData = getData(t);
    const [color, setColor] = useState(yellow);
    const [isAnimated, setIsAnimated] = useState(false);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);

    const data = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "portfolio/logos" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    `);

    useEffect(() => {
        setIsAnimated(true);
        setTimeout(() => setIsAnimated(false), 1500);
    }, [activeIndex]);

    useEffect(() => {
        if (!swiperInstance) return;

        setTimeout(() => setColor(getColor(activeIndex)), 1200);
        setTimeout(() => swiperInstance.slideTo(activeIndex), 1200);
    }, [activeIndex, swiperInstance]);

    return (
        <div className={`${reviews} ${className} ${color} ${isAnimated ? animated : ''}`}>
            <div className={`${container}`}>
                <Swiper
                    {...settings}
                    initialSlide={activeIndex}
                    className={`${isAnimated ? animatedSwiper : unanimatedSwiper}`}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                    {itemsData.map((item, idx) => {
                        const image = getImage(data.allFile.nodes[idx]);

                        return (
                            <SwiperSlide key={idx}>
                                {image && <GatsbyImage className={logo} image={image} alt="" />}

                                <p className={company}>{item.company}</p>
                                <blockquote className={blockquote}>
                                    <QuoteIcon className={icon} />
                                    <p className={quote}>{item.quote}</p>
                                    <footer className={author}>
                                        - {item.author}{' '}
                                        {item.position && <span>{item.position}</span>}
                                    </footer>
                                </blockquote>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

const getData = (t: ReturnType<typeof useI18next>['t']) => {
    return new Array(3).fill(0).map((item, index) => ({
        company: t(`portfolio.company.${index + 1}`),
        quote: t(`portfolio.quote.${index + 1}`),
        author: t(`portfolio.author.${index + 1}`),
        position: t(`portfolio.position.${index + 1}`),
    }));
};

const getColor = (activeIndex: number) => {
    switch (activeIndex) {
        case 0:
            return yellow;
        case 1:
            return blue;
        case 2:
            return purple;
    }
};

export default Reviews;
