import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import useIntersectionObserver from '../../hooks/use-intersection-observer';

import { section, heading, description, more, visible } from './about-hero.module.scss';

import Heading from '../atoms/heading';
import Semicircles from '../atoms/semicircles';

interface IAboutHeroProps {
    className?: string;
}

const AboutHero: React.FC<IAboutHeroProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const isOnScreen = useIntersectionObserver(sectionRef);

    return (
        <section
            className={`${section} ${isOnScreen ? visible : ''} ${className}`}
            ref={sectionRef}
        >
            <Semicircles />
            <Heading className={heading} as="h2" translatableTextKey="welcome.bottom.heading" />
            <p className={description}>{t('welcome.bottom.description')}</p>
            <p
                className={more}
                dangerouslySetInnerHTML={{
                    __html: t('welcome.bottom.more', {
                        interpolation: { escapeValue: false },
                    }),
                }}
            />
        </section>
    );
};

export default AboutHero;
