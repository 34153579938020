import React from 'react';
import { IScrollTo } from '../../models/scroll-to.model';

import {
    sectionWrapper,
    layoutWithMargins,
    layoutWithMarginLeft,
    layoutWithMarginRight,
    layoutWithoutMargins,
    container,
    semicircle,
    dark,
    light,
    hidden,
    visible,
    paddingTop,
    paddingBottom,
    minimumFullScreenHeight,
    arrowLight,
    arrowCircle,
} from './section-wrapper.module.scss';

import Semicircle, { TAccentColor, TSemicircleOrientation } from '../atoms/semicircle';
import ButtonScrollDown from '../atoms/button-scroll-down';

interface ISectionWrapperProps {
    className?: string;
    children?: React.ReactNode;
    isAbsolute?: boolean;
    color?: TAccentColor | 'random';
    orientation?: TSemicircleOrientation;
    bgColor?: 'light' | 'dark';
    isArrowDown?: boolean;
    isSemiCircle?: boolean;
    isOverflowHidden?: boolean;
    semiCircleClassName?: string;
    scrollToSection?: IScrollTo;
    isMarginLeft?: boolean;
    isMarginRight?: boolean;
    isPaddingTop?: boolean;
    isPaddingBottom?: boolean;
    isMinimumFullScreenHeight?: boolean;
}

const SectionWrapper: React.FC<ISectionWrapperProps> = ({
    className = '',
    children,
    color,
    orientation,
    bgColor = 'dark',
    isArrowDown = false,
    isSemiCircle = false,
    semiCircleClassName,
    scrollToSection,
    isOverflowHidden = true,
    isMarginLeft = true,
    isMarginRight = true,
    isPaddingTop = true,
    isPaddingBottom = true,
    isMinimumFullScreenHeight = true,
}) => {
    const layoutClass = getLayoutClass(isMarginLeft, isMarginRight);

    return (
        <div
            className={`${className} ${sectionWrapper} ${bgColor === 'dark' ? dark : light} ${
                isOverflowHidden ? hidden : visible
            } ${layoutClass} ${isPaddingTop && paddingTop} ${isPaddingBottom && paddingBottom} ${
                isMinimumFullScreenHeight && minimumFullScreenHeight
            }`}
        >
            <div className={container}>{children}</div>

            {isSemiCircle && (
                <Semicircle
                    orientation={orientation}
                    color={color}
                    className={`${semicircle} ${semiCircleClassName}`}
                    containerType={'half'}
                />
            )}

            {isArrowDown && (
                <ButtonScrollDown
                    className={`${bgColor === 'light' ? arrowLight : ''} ${arrowCircle}`}
                    scrollToSection={scrollToSection}
                />
            )}
        </div>
    );
};

function getLayoutClass(isMarginLeft: boolean, isMarginRight: boolean) {
    let layoutClass;

    if (isMarginLeft && isMarginRight) {
        layoutClass = layoutWithMargins;
    }

    if (isMarginLeft && !isMarginRight) {
        layoutClass = layoutWithMarginLeft;
    }

    if (!isMarginLeft && isMarginRight) {
        layoutClass = layoutWithMarginRight;
    }

    if (!isMarginLeft && !isMarginRight) {
        layoutClass = layoutWithoutMargins;
    }

    return layoutClass;
}

export default SectionWrapper;
