import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, onlyIcon } from './modal-button.module.scss';

import Cloud from '../../assets/images/svg/cloud.svg';

export type TButtonProps = {
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

const ModalButton: React.FC<TButtonProps> = ({ className = '', onClick }) => {
    const { t } = useI18next();
    const [isOnlyIcon, setIsOnlyIcon] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsOnlyIcon(true), 5000);
    }, []);

    return (
        <button
            className={`${className} ${button} ${isOnlyIcon && onlyIcon}`}
            onClick={onClick}
            onMouseOver={() => setIsOnlyIcon(false)}
            onMouseOut={() => setIsOnlyIcon(true)}
        >
            <Cloud /> <span>{t('contact-button')}</span>
        </button>
    );
};

export default ModalButton;
