import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { layout, blurred, noBlurred } from './index.module.scss';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Portfolio from '../components/organisms/portfolio';
import PortfolioMobile from '../components/organisms/portfolio-mobile';
import Partners from '../components/organisms/partners';
import FAQ from '../components/organisms/faq';
import About from '../components/organisms/about';
import Contact from '../components/organisms/contact';
import ModalForm from '../components/organisms/modal-form';

const IndexPage = () => {
    const [isFirstInitialization, setIsFirstInitialization] = useState(true);
    const [isBlurred, setIsBlurred] = useState(false);

    if (isFirstInitialization) {
        return (
            <MainLayout className={layout}>
                <ModalForm
                    isFirstInitialization={isFirstInitialization}
                    setIsFirstInitialization={setIsFirstInitialization}
                />
            </MainLayout>
        );
    }

    const blurredClass = isBlurred ? blurred : noBlurred;

    return (
        <MainLayout className={layout} headerClassName={blurredClass}>
            <ModalForm
                isFirstInitialization={isFirstInitialization}
                setIsBlurred={setIsBlurred}
                modalButtonclassName={blurredClass}
            />
            <div className={blurredClass}>
                <Hero />
                <PortfolioMobile />
                <Portfolio />
                <Partners />
                <FAQ />
                <Contact />
                <About />
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query IndexPage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default IndexPage;
