import React from 'react';

import { wrapper, section } from './hero.module.scss';

import SectionWrapper from '../molecues/section-wrapper';
import VideoHero from '../molecues/video-hero';
import WelcomeHero from '../molecues/welcome-hero';
import AboutHero from '../molecues/about-hero';

interface IHeroProps {
    className?: string;
}

const Hero: React.FC<IHeroProps> = ({ className = '' }) => {
    return (
        <SectionWrapper
            bgColor="dark"
            isPaddingTop={false}
            isPaddingBottom={false}
            isMinimumFullScreenHeight={false}
            isArrowDown={true}
            scrollToSection="wall-of-fame"
            className={`${className} ${wrapper}`}
        >
            <WelcomeHero className={section} />
            <VideoHero />
            <AboutHero className={section} />
        </SectionWrapper>
    );
};

export default Hero;
